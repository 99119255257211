import brush from "../images/brush.svg";
import brushBlack from "../images/brushBlack.svg";
import dummyData from "../images/DummyBanner.png";
import dummyImg from "../images/DummyImg.png";
import popup1 from "../images/popup1.svg";
import popup2 from "../images/popup2.svg";
import popup3 from "../images/popup3.svg";
import AccordianDown from "../images/accordianDown.svg";
import CopyLink from "../images/CopyLink.png";
import ChatLink from "../images/ChatLink.png";
import KaKao from "../images/Kakao.svg";
import Rectangle2 from "../images/Rectangle2.png";
import Rectangle3 from "../images/Rectangle3.png";
import Rectangle4 from "../images/Rectangle4.png";
import Rectangle5 from "../images/Rectangle5.png";
import Rectangle6 from "../images/Rectangle6.png";
const ImgData = {
    brush,
    brushBlack,
    dummyData,
    dummyImg,
    popup1,
    popup2,
    popup3,
    AccordianDown,
    CopyLink,
    ChatLink,
    KaKao,
    Rectangle2,
    Rectangle3,
    Rectangle4,
    Rectangle5,
    Rectangle6,
};

export default ImgData;
