const Footer = () => {
    return (
        <footer>
            <div className="footerWrap FlexCenter">
                <p>부산시 금정구 중앙대로 1629번길 16, 301동 221호</p>
                <p>(부곡동, 삼한골든뷰 에듀스테이션)</p>
                <p>사업자등록번호 : 216-15-73645 대표자 : 김진환</p>
            </div>
            <h1>여덟시도배</h1>
        </footer>
    );
};

export default Footer;
