import { useEffect, useRef, useState } from "react";
import ImgData from "../libs/images";
import Accordion from "./Accordion";
import { toast } from "react-toastify";
import { set } from "lodash";

const AccordionContent = [
    {
        title: "도배 시공 시작 시간은 언제인가요?",
        children: `<p>일반적으로 오전 8시에 현장에 도착합니다.</p>
        <p>이후 작업을 시작하여 오후 4~6시 사이에 마무리가 되어 철수합니다.</p>
        <p>이사 당일의 경우 오후 12~1시에 시작하여 오후 6시 쯤 마무리가 됩니다.</p>
        <p>(단, 시공 현장의 상황, 시공 방법 등에 따라 달라질 수 있습니다.)</p>`,
    },
    {
        title: "벽지를 모두 제거해 주나요?",
        children: `<p>여덟시도배는 필요에 따라 벽지를 제거하고, 울퉁불퉁한 면을 부직포로 완화하는 시공(초배 작업 : 합지 시 비용 추가)을 포함하고 있습니다.</p>

        <p><strong>벽지를 제거하지 않는 경우</strong></p>
        
        <ol>
            <li>벽면이 손상되는 경우 : 벽지를 제거했을 때 시멘트가 떨어져 나오는 경우가 있습니다. 도배가 가장 깔끔하게 나오는 것이 우선이기 때문에 이 경우는 벽지를 제거하지 않습니다.</li>
            <li>기존 벽지가 잘 붙어 있는 경우 : 기존 벽지가 잘 붙어 있는 경우, 벽면 굴곡을 완화해 도배가 더욱 깔끔하게 나옵니다.</li>
        </ol>
        
        <p>여덟시도배 시공팀은 벽지를 최대한으로 떼어 내는 것을 선호하고, 시공현장 확인 후 도배가 가장 잘 나오는 방향으로 진행하고 있습니다.</p>`,
    },
    {
        title: "부직포 초배 후 시공하는 것 맞나요?",
        children: `<p>여덟시도배는 필요에 따라 벽지를 제거하고 울퉁불퉁한 면을 부직포로 완화하는 시공을 포함하고 있습니다.</p>

        <p>실크의 경우, 벽면에는 손상된 벽 위주로 부직포를 시공하며 천장은 필요에 따라 부분적으로 진행하고 있습니다.</p>
        
        <p>합지의 경우, 시공팀 판단에 따라 필요한 부분에 대해서 부직포 시공을 진행하고 있습니다.</p>
        
        <p>전 벽면에 시공을 원하실 경우, 비용이 추가될 수 있기에 상담시 미리 말씀해주세요.</p>`,
    },
    {
        title: "천장은 무슨 색으로 해야 하나요?",
        children: `<p>천장은 93%이상 화이트 색상을 선택하십니다.</p>

        <p>간혹 벽지와 같은 색상으로 선택하시는 분들이 계시지만 천장이 너무 어두워지면 공간이 더욱 좁아보일 수 있어요.</p>
        
        <p>천장에 대한 특별한 선호가 없으시면 기본 화이트 천장지로 시공이 진행됩니다.</p>`,
    },
    {
        title: " 짐이 있을 때, 도배 시공은 어떻게 진행되나요?",
        children: `<ol>
        <li>거주 중일 경우 : 시공팀이 짐을 옮겨가며 도배합니다. 다만 책장의 책, 서랍 안의 내용물, 찬장 안의 물건 등 분실, 파손 위험이 있는 가구 안의 내용물을 시공 전 미리 비워 주시기 바랍니다.</li>
        <li>짐을 치워 빈 공간일 경우 : 미리 언급하신 물품을 제외한 모든 물품을 비워주세요. 현장 상황이 상담 내용과 다를 경우, 추가 비용이 발생하거나 시공이 취소될 수 있습니다.</li>
    </ol>`,
    },
    {
        title: "실크와 합지의 차이는 무엇인가요?",
        children: `<p>합지는 종이 재질, 실크는 종이에 코팅이 된 재질입니다.</p>

        <p>또한 합지는 겹치는 부분에 약 5mm의 선이 생기고, 실크는 이음선이 보이지 않게 깔끔하게 도배됩니다.</p>`,
    },
    {
        title: "전등은 제가 떼어내야 하나요?",
        children: `<p>도배 시 기존 전등은 떼어내고 도배 시공을 해드립니다. </p>
        <p>다만, 고객님께서 따로 구매하신 새로운 전등으로 교체해드리는 것은 서비스해드리지 않고 있으며 기존 전등을 그대로 사용하실 경우 미리 말씀해주시면 다시 달아드립니다.</p>
        <p>전등의 프레임까지 완전히 떼어내어 시공해드리지는 못 하고 있습니다.</p>
       `,
    },
    {
        title: "천장은 무슨 색으로 해야 하나요?",
        children: `<ol>
        <li>시공 후 문은 모두 닫아주세요. 실내에 바람이 불게되면 벽지의 한쪽부분이 먼저 마르게 됩니다. 고르게 건조되지 않으면 벽지가 터질 수 있으니, 시공 후 이틀간은 집의 모든 문을 닫아주세요.</li>
        <li>보일러는 끄거나, '외출' 상태로 시공 직후 젖은 벽지는 서서히 말라야합니다. 보일러로 급격하게 온도가 올라갈 경우, 벽지가 찢어지거나 터지는 현상이 발생하니 주의해주세요</li>
        <li>좀 더 여유있게 지켜본다. 겨울철에는 벽지마르는 시간이 2주 정도 걸립니다. 벽지가 울거나 뜨는 경우, 최소 2주간 지켜봐주세요. 이후에도 문제가 있으면 언제든 고객센터로 연락해주세요.</li>
        <li>습한 환경을 피해주세요. 겨울철에는 가습기의 사용과 환기의 부재로 집이 습해지는 경우가 많습니다. 이 경우 벽지가 일시적으로 뜨거나, 곰팡이가 발행할 수 있습니다. 환기와 제습기 등으로 집을 쾌적한 공간으로 만들어주세요.</li>
        <li>도배 후 3일동안 집을 비워둔다. 도배 후, 장판과 마루, 이사는 3일 뒤에 하는 것이 이상적입니다. 문을 열어두거나 보일러를 강하게 틀어야 하는 경우, 벽지가 쉽게 들뜨거나 터질 수 있습니다.</li>
    </ol>
    <p>겨울철 도배관리, '자연상태에서 최소 2일간 건조'가 핵심입니다.</p>`,
    },
];

const Popup = ({ setOpenPop, ClkEvent }) => {
    const [AccordionNums, setAccordionNums] = useState(0);
    const inputRef = useRef();
    const handleButtonClick = () => {
        navigator.clipboard.writeText(inputRef.current.value);
        toast.success("URL이 복사되었습니다.");
    };
    const ClkButton = () => {
        ClkEvent();
        setOpenPop(false);
    };

    useEffect(() => {
        document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
            "hidden";
        return () => {
            document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
                "scroll";
        };
    }, []);
    return (
        <div className={`popup`}>
            <div className="popup_container_component">
                <div className="popup_header">
                    <div
                        className="exit_btn"
                        onClick={() => {
                            setOpenPop(false);
                        }}
                    ></div>
                </div>
                <div className="popup_wrap FlexCenter">
                    <div className="popup_body FlexCenter">
                        <div className="popup_body_header FlexCenter">
                            <h1>매일 아침 여덟시, 공간을 바꾸고 있습니다</h1>
                            <p>
                                메뉴얼화된 견적 시스템과 시공 프로세스로 <br />
                                상담부터 공사의 마무리까지 최상의 인테리어
                                경험을 제공합니다.
                            </p>
                        </div>
                        <div className="popup_body_content">
                            <div
                                style={{ background: "#D3FFCC" }}
                                className="popup_body_img_wrap FlexCenter"
                            >
                                <img src={ImgData.popup1} alt="popup_img" />
                            </div>
                            <div className="popup_body_content_thing">
                                <h1>체계적이고 효율적인 프로세스</h1>
                                <p>
                                    견적서, 계약서, 자재선택과 진행상황까지
                                    나만의 페이지에서 확인할 수 있어요
                                </p>
                                <p>
                                    마감 체크와 공사 후 A/S까지 여덟시도배가
                                    함께합니다.
                                </p>
                            </div>
                        </div>
                        <div className="popup_body_content">
                            <div
                                style={{ background: "#FCEBD7" }}
                                className="popup_body_img_wrap FlexCenter"
                            >
                                <img src={ImgData.popup2} alt="popup_img" />
                            </div>
                            <div className="popup_body_content_thing">
                                <h1>가격정찰제</h1>
                                <p>
                                    견적의 변동 없이 처음 가격 그대로 동일한
                                    평형이라면 동일한 기준 적용
                                </p>
                                <p>정직하고 건전한 시장을 만듭니다.</p>
                            </div>
                        </div>
                        <div className="popup_body_content">
                            <div
                                style={{ background: "#CEF6FF" }}
                                className="popup_body_img_wrap FlexCenter"
                            >
                                <img src={ImgData.popup3} alt="popup_img" />
                            </div>
                            <div className="popup_body_content_thing">
                                <h1>건강과 환경을 생각한 자재와 부자재</h1>
                                <p>
                                    항균,항곰팡이, 식물성 코팅 등을 담은 40년
                                    노하우의 개나리벽지를 사용합니다.
                                </p>
                                <p>
                                    여덟시는 건강하고 환경적인 시공을
                                    지향합니다.
                                </p>
                            </div>
                        </div>
                        <div className="ConfirmBoxWrapper FlexCenter">
                            <div className="ConfirmBoxWrapperBox">
                                <div className="ConfirmBoxWrapperBoxTitle">
                                    <h1>상담신청 이후 진행과정 [4단계]</h1>
                                </div>
                                <div className="ConfirmBoxWrapperBoxContent">
                                    <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                        <div className="numberCircle FlexCenter">
                                            1
                                        </div>
                                        <div className="ConfirmBoxWrapperBoxContentItemText">
                                            상세견적 상담
                                        </div>
                                    </div>
                                    <div className="ConfirmBoxWrapperBoxContentIArrow"></div>
                                    <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                        <div className="numberCircle FlexCenter">
                                            2
                                        </div>
                                        <div className="ConfirmBoxWrapperBoxContentItemText">
                                            견적서 확인 및 결제
                                        </div>
                                    </div>
                                    <div className="ConfirmBoxWrapperBoxContentIArrow"></div>
                                    <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                        <div className="numberCircle FlexCenter">
                                            3
                                        </div>
                                        <div className="ConfirmBoxWrapperBoxContentItemText">
                                            벽지색상 선택
                                        </div>
                                    </div>
                                    <div className="ConfirmBoxWrapperBoxContentIArrow"></div>
                                    <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                        <div className="numberCircle FlexCenter">
                                            4
                                        </div>
                                        <div className="ConfirmBoxWrapperBoxContentItemText">
                                            도배시공 🥳
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="AccordionWrap">
                                <div className="AccordionTitle">
                                    자주 묻는 질문
                                </div>
                                {AccordionContent.map((item, index) => {
                                    return (
                                        <Accordion
                                            key={index}
                                            title={item.title}
                                            children={item.children}
                                            value={index}
                                            setValue={setAccordionNums}
                                            isOpen={AccordionNums === index}
                                        />
                                    );
                                })}
                            </div>
                            <div className="ConfirmBoxLink FlexCenter">
                                <h1>3초견적 링크 복사/공유하기</h1>
                                {/* <div className="ConfirmBoxLinkBtnWrap FlexCenter">
                                    <div className="ConfirmBoxLinkBtn FlexCenter">
                                        <img
                                            src={ImgData.CopyLink}
                                            alt="Link"
                                        />
                                        <p>링크복사</p>
                                    </div>
                                    <div className="ConfirmBoxLinkBtn FlexCenter">
                                        <img
                                            src={ImgData.ChatLink}
                                            alt="Link"
                                        />
                                        <p>문자</p>
                                    </div>
                                    <div className="ConfirmBoxLinkBtn FlexCenter">
                                        <img src={ImgData.KaKao} alt="Link" />
                                        <p>카카오톡</p>
                                    </div>
                                </div> */}
                                <div className="ConfirmBoxLinkInputBox">
                                    <input
                                        type="text"
                                        value="http://www.8amdobae.co.kr"
                                        readOnly
                                        ref={inputRef}
                                    />
                                    <button
                                        className="FlexCenter"
                                        onClick={handleButtonClick}
                                    >
                                        URL 복사
                                    </button>
                                </div>
                            </div>
                            <div
                                className="ConfirmBoxLinkLastBtn FlexCenter"
                                style={{ cursor: "pointer" }}
                                onClick={ClkButton}
                            >
                                3초견적 바로가기
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
