import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["scd"] = "APLYNXADMINSCD";
// let storedState = localStorage.getItem("Login-Storage");
// storedState = JSON.parse(storedState);
// let token = storedState?.state?.mbData?.token;

const getData = (url) => {
    let dm = "https://api.8amdobae.co.kr/"; // PNSOFT TEST SERVER
    // axios.defaults.headers.common["authorization"] = token;
    return axios.post(dm + `${url}`);
};

const postData = (url, sender = {}) => {
    let dm = "https://api.8amdobae.co.kr/"; // PNSOFT TEST SERVER
    // axios.defaults.headers.common["authorization"] = token;

    return axios.post(dm + `${url}`, sender);
};

export function useGetData(url) {
    return useQuery({ queryKey: [url], queryFn: () => getData(url) });
}

export function usePostData(url, sender) {
    return useMutation({
        mutationKey: [url],
        mutationFn: (data) => postData(url, data || sender),
    });
}
