import React, { useState, useEffect, useRef } from "react";
import ImgData from "../libs/images";

function Accordion({ title, children, value, setValue, isOpen }) {
    const contentRef = useRef(null);

    useEffect(() => {
        contentRef.current.style.maxHeight = isOpen
            ? `${contentRef.current.scrollHeight}px`
            : "0px";
    }, [contentRef, isOpen]);

    const toogleIsActive = () => {
        setValue(value);
    };

    return (
        <div className="accordion-section">
            <div className="accordion-title" onClick={toogleIsActive}>
                {title}
                <img
                    src={ImgData.AccordianDown}
                    style={{
                        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.6s ease",
                    }}
                />
            </div>

            <div
                ref={contentRef}
                className={`accordion-content ${isOpen ? "active" : ""}`}
            >
                <div
                    className="accordion-text"
                    dangerouslySetInnerHTML={{ __html: children }}
                ></div>
            </div>
        </div>
    );
}

export default Accordion;
