import { useEffect, useRef, useState } from "react";
import HelmetWrap from "../components/Helmet";
import ImgData from "../libs/images";
import { ko } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";
import Popup from "../components/popup";
import { useNavigate } from "react-router-dom";
import routes from "../libs/routes";
import consts from "../libs/consts";
import ApiData from "../libs/api";
import Footer from "../components/footer";
import { toast } from "react-toastify";
import { useGetData, usePostData } from "../../util/service";

function Main() {
    const navigate = useNavigate();

    const {
        data: bannerData,
        isLoadingm,
        isError,
    } = useGetData(ApiData.BannerList);
    const PriceShow = usePostData(ApiData.PriceShow);
    const CounselingSubmit = usePostData(ApiData.counseling);
    const { data: priceWallPaperData } = useGetData(ApiData.PriceWallPaper);
    const [mainChk, setMainChk] = useState(1);

    const [openPop, setOpenPop] = useState(false);
    const [selectedHomeType, setSelectedHomeType] = useState(1);
    const [area, setArea] = useState(1);
    const [pyeong, setPyeong] = useState(24);
    const [room, setRoom] = useState(1);
    const [selectedBeranda, setSelectedBeranda] = useState(2);
    const [selectedCeiling, setSelectedCeiling] = useState(2);
    const [wallColor, setWallColor] = useState(1);
    const [constructionRange, setConstructionRange] = useState(1);
    const [phoneNum, setPhoneNum] = useState("");
    const [startDate, setStartDate] = useState();
    const [selectLivingEnviron, setSelectLivingEnviron] = useState(0);
    const [dobeaRadio, setDobeaRadio] = useState(0);
    const [janpanRadio, setJangpanRadio] = useState(0);
    const [agreeChk, setAgreeChk] = useState(false);
    const [firstValue, setFirstValue] = useState(0);
    const [secondValue, setSecondValue] = useState(0);
    const [thirdValue, setThirdValue] = useState(0);
    const [wallpaperPrice, setWallpaperPrice] = useState(0);
    const element = useRef(null);

    const PriceShowApi = () => {
        let sender = {
            type: mainChk,
            living: selectedHomeType,
            area: area,
            pyeong: pyeong,
            veranda: selectedBeranda,
        };
        if (mainChk === 1) {
            sender.ceiling = selectedCeiling;
        } else {
            sender.room = constructionRange;
        }
        PriceShow.mutateAsync(sender)
            .then(({ data }) => {
                setFirstValue(data?.first_value);
                setSecondValue(data?.second_value);
                setThirdValue(data?.third_value);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const onMoveToElement = () => {
        element.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    // const { movingHere, MovingClk } = useMoveScrool();
    // const [personalInfoDetailPop, setPersonalInfoDetailPop] = useState(true);

    const handleChangeHomeType = (event) => {
        setSelectedHomeType(parseInt(event.target.value));
    };
    const handleChangeBeranda = (event) => {
        console.log(event.target.value);
        setSelectedBeranda(event.target.value);
    };
    const handleChangeCeiling = (event) => {
        setSelectedCeiling(event.target.value);
    };
    const hadleChangeLivingEnviron = (event) => {
        setSelectLivingEnviron(event.target.value);
    };
    const handleConstructionRange = (event) => {
        setConstructionRange(event.target.value);
    };
    const SubmitContent = () => {
        let sender = {
            mb_phone: phoneNum,
            sigong_date: startDate,
            sigong_type: parseInt(mainChk),
            residence: parseInt(selectedHomeType),
            area: parseInt(area),
            area_pyeong: parseInt(pyeong),
            area_room_cnt: parseInt(room),
            veranda: parseInt(selectedBeranda),
            today_status: parseInt(selectLivingEnviron),
        };
        if (mainChk === 1) {
            sender.ceiling = parseInt(selectedCeiling);
            sender.dobae_product = parseInt(dobeaRadio);
            sender.wall_color_cnt = wallColor;
            sender.dobae_price =
                dobeaRadio === 1
                    ? parseInt(
                          firstValue * 10000 + wallpaperPrice * (wallColor - 1)
                      )
                    : dobeaRadio === 2
                    ? parseInt(
                          secondValue * 10000 + wallpaperPrice * (wallColor - 1)
                      )
                    : parseInt(
                          thirdValue * 10000 + wallpaperPrice * (wallColor - 1)
                      );
        } else {
            sender.zangpan_product = parseInt(janpanRadio);
            sender.zangpan_range = parseInt(constructionRange);
            sender.zangpan_price =
                janpanRadio === 1
                    ? parseInt(firstValue * 10000)
                    : janpanRadio === 2
                    ? parseInt(secondValue * 10000)
                    : janpanRadio === 3
                    ? parseInt(thirdValue * 10000)
                    : parseInt(0);
        }

        if (selectedHomeType === 0) {
            toast.error("집 타입을 선택해주세요");
            return;
        }
        //이 부분에 집타입 면적이 들어가야 함
        else if (selectedBeranda === 0) {
            toast.error("베란다 확장 여부를 선택해주세요");
            return;
        } else if (mainChk === 1 && selectedCeiling === 0) {
            toast.error("천장 도배 여부를 선택해주세요");
            return;
        } else if (mainChk === 2 && constructionRange === 0) {
            toast.error("시공 범위를 선택해주세요");
            return;
        } else if (selectLivingEnviron === 0) {
            toast.error("시공당일 주거환경을 선택해주세요");
            return;
        } else if (mainChk === 1 && dobeaRadio === 0) {
            toast.error("도배 종류를 선택해주세요");
            return;
        } else if (mainChk === 2 && janpanRadio === 0) {
            toast.error("장판 두께를 선택해주세요");
            return;
        } else if (phoneNum === "") {
            toast.error("연락처를 입력해주세요");
            return;
        } else if (phoneNum.includes("-")) {
            // sender.getPhone = recevingPhone.replace(/-/g, "");
        } else if (phoneNum.includes(" ")) {
            // sender.getPhone = recevingPhone.replace(/ /g, "");
        } else if (!consts.phonePattern.test(phoneNum)) {
            toast.error("휴대폰 번호를 제대로 입력해주세요.");
            return;
        } else if (startDate === undefined) {
            toast.error("시공일을 선택해주세요");
            return;
        } else if (agreeChk === false) {
            console.log(agreeChk);
            toast.error("개인정보 수집/이용 동의를 체크해주세요");
            return;
        }

        console.log("sender", sender);
        CounselingSubmit.mutateAsync(sender)
            .then((data) => {
                navigate(routes.Confirm);
                toast.success("상담신청이 완료되었습니다.");
            })
            .catch((e) => {
                console.log(e);
                toast.error(e.reseponse.data);
            });
    };

    useEffect(() => {
        setSelectedHomeType(1);
        setSelectedBeranda(2);
        setSelectedCeiling(2);
        setSelectLivingEnviron(0);
        setDobeaRadio(0);
        setJangpanRadio(0);
        setStartDate();
        setAgreeChk(false);
    }, [mainChk]);

    useEffect(() => {
        PriceShowApi();
    }, [
        mainChk,
        selectedHomeType,
        area,
        pyeong,
        selectedBeranda,
        selectedCeiling,
        constructionRange,
    ]);
    useEffect(() => {
        console.log(priceWallPaperData);
        if (priceWallPaperData) {
            setWallpaperPrice(
                priceWallPaperData?.data?.wall_cnt_price * 0.0001
            );
        }
    }, [priceWallPaperData]);
    return (
        <>
            <HelmetWrap />
            {/* {personalInfoDetailPop && (
                <PersonalInfoPopup setOpenPop={setPersonalInfoDetailPop} />
            )} */}
            {openPop && (
                <Popup setOpenPop={setOpenPop} ClkEvent={onMoveToElement} />
            )}
            <header>
                <div className="CompanyLogo">
                    <h4>8AMDOBAE 여덟시도배 </h4>
                </div>
                <div className="headerWrap FlexCenter">
                    <div className="logo FlexCenter">
                        <img src={ImgData.brush} alt="logo" />
                    </div>
                    <div className="title">
                        <p>도배 한번씩은 해본 사람들이 찾는 곳</p>
                        <h1>여덟시도배</h1>
                    </div>

                    <div className="TitleBtn FlexCenter">
                        <button onClick={onMoveToElement}>
                            3초견적 바로가기{" "}
                        </button>
                    </div>
                </div>
            </header>
            <main>
                <div className="Main1 FlexCenter" ref={element}>
                    <div className="mainWrap">
                        <div className="mainTop">
                            <div className="mainChooseWrap FlexCenter">
                                <div
                                    className={`mainChoose FlexCenter ${
                                        mainChk === 1 ? "active" : ""
                                    }`}
                                    onClick={() => setMainChk(1)}
                                >
                                    도배
                                </div>
                                <div
                                    className={`mainChoose FlexCenter ${
                                        mainChk === 2 ? "active" : ""
                                    }`}
                                    onClick={() => setMainChk(2)}
                                >
                                    장판
                                </div>
                            </div>
                            <div className="subChooseWrapper ">
                                <p className="subTitle">
                                    도배 견적을 위해 집 타일과 면적을
                                    선택해주세요.
                                </p>
                                <div className="SubChooseWrap">
                                    <div className="subChoose FlexCenter">
                                        <input
                                            id="apartment"
                                            type="radio"
                                            value={1}
                                            checked={selectedHomeType === 1}
                                            onChange={handleChangeHomeType}
                                            className="hiddenRadio"
                                            name="homeType"
                                        />
                                        <label
                                            htmlFor="apartment"
                                            className="label FlexCenter"
                                        >
                                            아파트
                                        </label>
                                    </div>
                                    <div className="subChoose FlexCenter">
                                        <input
                                            id="villa"
                                            type="radio"
                                            value={2}
                                            checked={selectedHomeType === 2}
                                            onChange={handleChangeHomeType}
                                            className="hiddenRadio"
                                            name="homeType"
                                        />
                                        <label
                                            htmlFor="villa"
                                            className="label FlexCenter"
                                        >
                                            빌라/오피스텔
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <p className="ColorWhite">집 전체 면적</p>
                                    <div
                                        style={{ gap: "8px" }}
                                        className="SubChooseWrap"
                                    >
                                        <div className="SubChooseSelect">
                                            <select
                                                value={area}
                                                onChange={(e) => {
                                                    setArea(
                                                        parseInt(e.target.value)
                                                    );
                                                }}
                                            >
                                                <option value={1}>
                                                    공급면적
                                                </option>
                                                <option value={2}>
                                                    전용면적
                                                </option>
                                            </select>
                                        </div>
                                        <div className="SubChooseSelect">
                                            <select
                                                value={pyeong}
                                                onChange={(e) => {
                                                    setPyeong(
                                                        parseInt(e.target.value)
                                                    );
                                                }}
                                            >
                                                {Array.from(
                                                    {
                                                        length:
                                                            area === 1
                                                                ? 40
                                                                : 40,
                                                    },
                                                    (_, i) =>
                                                        area === 1
                                                            ? i + 15
                                                            : i + 12
                                                ).map((value) => (
                                                    <option
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {value}평
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="SubChooseSelect">
                                            <select
                                                value={room}
                                                onChange={(e) => {
                                                    setRoom(
                                                        parseInt(e.target.value)
                                                    );
                                                }}
                                            >
                                                {Array.from(
                                                    { length: 10 },
                                                    (_, i) => i + 1
                                                ).map((value) => (
                                                    <option
                                                        key={value}
                                                        value={value}
                                                    >
                                                        방 {value}개
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="ColorWhite">베란다 확장</p>
                                    <div className="SubChooseWrap">
                                        <div className="subChoose FlexCenter">
                                            <input
                                                id="berandaNo"
                                                type="radio"
                                                value={2}
                                                checked={
                                                    parseInt(
                                                        selectedBeranda
                                                    ) === 2
                                                }
                                                onChange={handleChangeBeranda}
                                                className="hiddenRadio"
                                                name="beranda"
                                            />
                                            <label
                                                htmlFor="berandaNo"
                                                className="label FlexCenter"
                                            >
                                                베란다 확장 없음
                                            </label>
                                        </div>
                                        <div className="subChoose FlexCenter">
                                            <input
                                                id="berandaHave"
                                                type="radio"
                                                value={1}
                                                checked={
                                                    parseInt(
                                                        selectedBeranda
                                                    ) === 1
                                                }
                                                onChange={handleChangeBeranda}
                                                className="hiddenRadio"
                                                name="beranda"
                                            />
                                            <label
                                                htmlFor="berandaHave"
                                                className="label FlexCenter"
                                            >
                                                베란다 확장 있음
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {mainChk === 1 ? (
                                    <div
                                        style={{ display: "flex", gap: "8px" }}
                                    >
                                        <div className="leftWrap">
                                            <p className="ColorWhite">
                                                천장 도배
                                            </p>
                                            <div className="SubChooseWrap">
                                                <div className="subChoose FlexCenter">
                                                    <input
                                                        id="ceilingNo"
                                                        type="radio"
                                                        value={parseInt(2)}
                                                        checked={
                                                            parseInt(
                                                                selectedCeiling
                                                            ) === 2
                                                        }
                                                        onChange={
                                                            handleChangeCeiling
                                                        }
                                                        className="hiddenRadio"
                                                        name="ceiling"
                                                    />
                                                    <label
                                                        htmlFor="ceilingNo"
                                                        className="label FlexCenter"
                                                    >
                                                        천장 도배 없음
                                                    </label>
                                                </div>
                                                <div className="subChoose FlexCenter">
                                                    <input
                                                        id="ceilingHave"
                                                        type="radio"
                                                        value={1}
                                                        checked={
                                                            parseInt(
                                                                selectedCeiling
                                                            ) === 1
                                                        }
                                                        onChange={
                                                            handleChangeCeiling
                                                        }
                                                        className="hiddenRadio"
                                                        name="ceiling"
                                                    />
                                                    <label
                                                        htmlFor="ceilingHave"
                                                        className="label FlexCenter"
                                                    >
                                                        천장 도배 포함
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rightWrap">
                                            <p className="ColorWhite">
                                                벽지 색상
                                            </p>
                                            <div
                                                style={{ gap: "8px" }}
                                                className="SubChooseWrap"
                                            >
                                                <div className="SubChooseSelect">
                                                    <select
                                                        value={wallColor}
                                                        onChange={(e) => {
                                                            setWallColor(
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {Array.from(
                                                            { length: 10 },
                                                            (_, i) => i + 1
                                                        ).map((value) => (
                                                            <option
                                                                key={value}
                                                                value={value}
                                                            >
                                                                {value}개
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p className="ColorWhite">시공 범위</p>
                                        <div className="SubChooseWrap">
                                            <div className="subChoose FlexCenter">
                                                <input
                                                    id="constructRange1"
                                                    type="radio"
                                                    value={1}
                                                    checked={
                                                        parseInt(
                                                            constructionRange
                                                        ) === 1
                                                    }
                                                    onChange={
                                                        handleConstructionRange
                                                    }
                                                    className="hiddenRadio"
                                                    name="range"
                                                />
                                                <label
                                                    htmlFor="constructRange1"
                                                    className="label FlexCenter"
                                                >
                                                    집 전체 시공
                                                </label>
                                            </div>
                                            <div className="subChoose FlexCenter">
                                                <input
                                                    id="constructRange2"
                                                    type="radio"
                                                    value={2}
                                                    checked={
                                                        parseInt(
                                                            constructionRange
                                                        ) === 2
                                                    }
                                                    onChange={
                                                        handleConstructionRange
                                                    }
                                                    className="hiddenRadio"
                                                    name="range"
                                                />
                                                <label
                                                    htmlFor="constructRange2"
                                                    className="label FlexCenter"
                                                >
                                                    방만 시공
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <p className="ColorWhite">
                                        시공당일 주거환경
                                    </p>
                                    <div className="SubChooseWrap">
                                        <div className="subChoose FlexCenter">
                                            <input
                                                id="noHome"
                                                type="radio"
                                                value={1}
                                                checked={
                                                    parseInt(
                                                        selectLivingEnviron
                                                    ) === 1
                                                }
                                                onChange={
                                                    hadleChangeLivingEnviron
                                                }
                                                className="hiddenRadio"
                                                name="stayHome"
                                            />
                                            <label
                                                htmlFor="noHome"
                                                className="label FlexCenter"
                                            >
                                                빈집 가구 없음
                                            </label>
                                        </div>
                                        <div className="subChoose FlexCenter">
                                            <input
                                                id="yesHome"
                                                type="radio"
                                                value={2}
                                                checked={
                                                    parseInt(
                                                        selectLivingEnviron
                                                    ) === 2
                                                }
                                                onChange={
                                                    hadleChangeLivingEnviron
                                                }
                                                className="hiddenRadio"
                                                name="stayHome"
                                            />
                                            <label
                                                htmlFor="yesHome"
                                                className="label FlexCenter"
                                            >
                                                거주중 가구 있음
                                            </label>
                                        </div>
                                        <div className="subChoose FlexCenter">
                                            <input
                                                id="MovingDay"
                                                type="radio"
                                                value={3}
                                                checked={
                                                    parseInt(
                                                        selectLivingEnviron
                                                    ) === 3
                                                }
                                                onChange={
                                                    hadleChangeLivingEnviron
                                                }
                                                className="hiddenRadio"
                                                name="stayHome"
                                            />
                                            <label
                                                htmlFor="MovingDay"
                                                className="label FlexCenter"
                                            >
                                                이사 당일
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mainBottom FlexCenter">
                            <h4>시공을 원하는 상품을 선택해주세요</h4>
                            {mainChk === 1 ? (
                                <>
                                    <div className="radioBtnWrap FlexCenter">
                                        <div className="radioBtn FlexCenter">
                                            <div className="radioBtnTitle">
                                                실크도배
                                            </div>
                                            <div className="radioBtnPrice">
                                                {firstValue +
                                                    wallpaperPrice *
                                                        (wallColor - 1)}
                                                만원{" "}
                                                {parseInt(
                                                    selectLivingEnviron
                                                ) === 2 ||
                                                parseInt(
                                                    selectLivingEnviron
                                                ) === 3
                                                    ? " +"
                                                    : ""}
                                            </div>
                                            <input
                                                id="priceRadio1"
                                                type="radio"
                                                className="priceRadio"
                                                name="priceRadio"
                                                onChange={() => {
                                                    setDobeaRadio(1);
                                                }}
                                            />
                                            <label htmlFor="priceRadio1"></label>
                                        </div>
                                        <div className="radioBtn FlexCenter">
                                            <div className="radioBtnTitle">
                                                합지도배
                                            </div>
                                            <div className="radioBtnPrice">
                                                {secondValue +
                                                    wallpaperPrice *
                                                        (wallColor - 1)}
                                                만원
                                                {parseInt(
                                                    selectLivingEnviron
                                                ) === 2 ||
                                                parseInt(
                                                    selectLivingEnviron
                                                ) === 3
                                                    ? " +"
                                                    : ""}
                                            </div>
                                            <input
                                                id="priceRadio2"
                                                type="radio"
                                                className="priceRadio"
                                                name="priceRadio"
                                                onChange={() => {
                                                    setDobeaRadio(2);
                                                }}
                                            />
                                            <label htmlFor="priceRadio2"></label>
                                        </div>
                                        <div className="radioBtn FlexCenter">
                                            <div className="radioBtnTitle">
                                                혼합도배
                                            </div>
                                            <div className="radioBtnPrice">
                                                {thirdValue +
                                                    wallpaperPrice *
                                                        (wallColor - 1)}
                                                만원
                                                {parseInt(
                                                    selectLivingEnviron
                                                ) === 2 ||
                                                parseInt(
                                                    selectLivingEnviron
                                                ) === 3
                                                    ? " +"
                                                    : ""}
                                            </div>
                                            <input
                                                id="priceRadio3"
                                                type="radio"
                                                className="priceRadio"
                                                name="priceRadio"
                                                onChange={() => {
                                                    setDobeaRadio(3);
                                                }}
                                            />
                                            <label htmlFor="priceRadio3"></label>
                                        </div>
                                    </div>
                                    <p
                                        style={{
                                            color: "#777",
                                            textAlign: "center",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {parseInt(selectLivingEnviron) === 2 ||
                                        parseInt(selectLivingEnviron) === 3
                                            ? "거주중/이사당일 인건비가 추가됩니다. 상담을 통해 확인해주세요."
                                            : ""}
                                    </p>
                                </>
                            ) : (
                                <div className="radioBtnWrap FlexCenter">
                                    <div className="radioBtn FlexCenter">
                                        <div className="radioBtnTitle">
                                            장판 1.8mm
                                        </div>
                                        <div className="radioBtnPrice">
                                            {firstValue}
                                            만원
                                            {parseInt(selectLivingEnviron) ===
                                                2 ||
                                            parseInt(selectLivingEnviron) === 3
                                                ? " +"
                                                : ""}
                                        </div>
                                        <input
                                            id="priceRadio1"
                                            type="radio"
                                            className="priceRadio"
                                            name="priceRadio"
                                            onChange={() => {
                                                setJangpanRadio(1);
                                            }}
                                        />
                                        <label htmlFor="priceRadio1"></label>
                                    </div>
                                    <div className="radioBtn FlexCenter">
                                        <div className="radioBtnTitle">
                                            장판 2.2mm
                                        </div>
                                        <div className="radioBtnPrice">
                                            {secondValue}
                                            만원
                                            {parseInt(selectLivingEnviron) ===
                                                2 ||
                                            parseInt(selectLivingEnviron) === 3
                                                ? " +"
                                                : ""}
                                        </div>
                                        <input
                                            id="priceRadio2"
                                            type="radio"
                                            className="priceRadio"
                                            name="priceRadio"
                                            onChange={() => {
                                                setJangpanRadio(2);
                                            }}
                                        />
                                        <label htmlFor="priceRadio2"></label>
                                    </div>
                                    <div className="radioBtn FlexCenter">
                                        <div className="radioBtnTitle">
                                            장판 2.7mm
                                        </div>
                                        <div className="radioBtnPrice">
                                            {thirdValue}
                                            만원
                                            {parseInt(selectLivingEnviron) ===
                                                2 ||
                                            parseInt(selectLivingEnviron) === 3
                                                ? " +"
                                                : ""}
                                        </div>
                                        <input
                                            id="priceRadio3"
                                            type="radio"
                                            className="priceRadio"
                                            name="priceRadio"
                                            onChange={() => {
                                                setJangpanRadio(3);
                                            }}
                                        />
                                        <label htmlFor="priceRadio3"></label>
                                    </div>
                                    <div className="radioBtn FlexCenter">
                                        <div className="radioBtnTitle">
                                            장판 3.0mm 이상
                                        </div>
                                        <div className="radioBtnPrice">
                                            문의 요망
                                        </div>

                                        <input
                                            id="priceRadio4"
                                            type="radio"
                                            className="priceRadio"
                                            name="priceRadio"
                                            onChange={() => {
                                                setJangpanRadio(4);
                                            }}
                                        />
                                        <label htmlFor="priceRadio4"></label>
                                    </div>
                                </div>
                            )}
                            <div className="ReqConsultWrap">
                                <p className="consultTitle">
                                    상담신청해 주시면 함께 고민해드립니다.
                                </p>
                                <div className="ReqConsult">
                                    <div className="ReqConsultInput">
                                        <p>연락처</p>
                                        <input
                                            type="text"
                                            className="FlexCenter"
                                            value={phoneNum}
                                            placeholder="010-0000-0000"
                                            onChange={(e) => {
                                                setPhoneNum(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="ReqConsultInput">
                                        <p>시공일</p>

                                        <DatePicker
                                            showIcon
                                            locale={ko}
                                            selected={startDate}
                                            onChange={(date) =>
                                                setStartDate(date)
                                            }
                                            dateFormat={"yyyy-MM-dd"}
                                            placeholderText={"연도-월-일"}
                                            icon={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M7.50008 0.833374V2.50004H12.5001V0.833374H14.1667V2.50004H17.5001C17.9603 2.50004 18.3334 2.87314 18.3334 3.33337V16.6667C18.3334 17.127 17.9603 17.5 17.5001 17.5H2.50008C2.03985 17.5 1.66675 17.127 1.66675 16.6667V3.33337C1.66675 2.87314 2.03985 2.50004 2.50008 2.50004H5.83341V0.833374H7.50008ZM16.6667 9.16671H3.33341V15.8334H16.6667V9.16671ZM5.83341 4.16671H3.33341V7.50004H16.6667V4.16671H14.1667V5.83337H12.5001V4.16671H7.50008V5.83337H5.83341V4.16671Z"
                                                        fill="#07090C"
                                                    />
                                                </svg>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="PrivacyChkWrap">
                                <div className="PrivacyChk">
                                    <input
                                        type="checkbox"
                                        id="privacy"
                                        value={agreeChk}
                                        checked={agreeChk}
                                        onChange={() => {
                                            setAgreeChk(!agreeChk);
                                        }}
                                    />
                                    <label htmlFor="privacy">
                                        개인정보 수집/이용 동의
                                    </label>
                                </div>
                                {/* <p>자세히</p> */}
                            </div>
                            <div
                                className="ReqConsultBtn FlexCenter"
                                onClick={() => {
                                    SubmitContent();
                                }}
                            >
                                <button>위 조건으로 상담신청 합니다.</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Main2 FlexCenter">
                    <div className="mainWrap">
                        <div className="bannerWrap ">
                            <p>여덟시 기획전</p>
                            {bannerData?.data?.map((item, index) => {
                                return (
                                    <div className="banner" key={index}>
                                        <img
                                            src={`${consts.ImgState}${item.img_url}`}
                                            alt="img"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="Main3 FlexCenter">
                    <div className="mainWrap FlexCenter">
                        <h1 className="desktop-header">
                            매일 아침 여덟시, 공간을 바꾸고 있습니다
                        </h1>
                        <h1 className="mobile-header">
                            매일 아침 여덟시, <br />
                            공간을 바꾸고 있습니다
                        </h1>
                        <p>
                            메뉴얼화된 견적 시스템과 시공 프로세스로 <br />{" "}
                            상담부터 공사의 마무리까지 최상의 인테리어 경험을
                            제공합니다.
                        </p>
                        <div className="imgWrap">
                            <div className="img">
                                <img src={ImgData.dummyImg} alt="img" />
                            </div>
                            <div className="img">
                                <img src={ImgData.Rectangle2} alt="img" />
                            </div>
                            <div className="img">
                                <img src={ImgData.Rectangle3} alt="img" />
                            </div>
                            <div className="img">
                                <img src={ImgData.Rectangle4} alt="img" />
                            </div>
                            <div className="img">
                                <img src={ImgData.Rectangle5} alt="img" />
                            </div>
                            <div className="img">
                                <img src={ImgData.Rectangle6} alt="img" />
                            </div>
                            <div className="img">
                                <img src={ImgData.Rectangle2} alt="img" />
                            </div>
                            <div className="img">
                                <img src={ImgData.dummyImg} alt="img" />
                            </div>
                        </div>
                        {/* <div className="Main3BtnWrap FlexCenter">
                            <button
                                onClick={() => {
                                    setOpenPop(!openPop);
                                }}
                            >
                                여덟시도배 서비스 소개
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className="Main4 FlexCenter">
                    <div className="Main4Wrap">
                        <p>부산, 양산, 김해지역 서비스 중입니다.</p>
                    </div>
                    <h1 className="desktop-header">고객센터 051-581-5258</h1>
                    <h1 className="mobile-header">
                        고객센터 <br />
                        051-581-5258
                    </h1>

                    <div className="Main4BtnWrap FlexCenter">
                        <button onClick={onMoveToElement}>
                            간편견적 바로가기
                        </button>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}
export default Main;
