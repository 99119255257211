import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./assets/libs/routes";
import Home from "./assets/pages/Home";
import Confirm from "./assets/pages/Confirm";

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path={"/"} element={<Home />} />
                <Route exact path={routes.Home} element={<Home />} />
                <Route exact path={routes.Confirm} element={<Confirm />} />
            </Routes>
        </BrowserRouter>
    );
}
export default Router;
