import { useRef } from "react";
import Footer from "../components/footer";
import ImgData from "../libs/images";
import { toast } from "react-toastify";
import routes from "../libs/routes";
import { useNavigate } from "react-router-dom";
function Confirm() {
    const navigate = useNavigate();
    const inputRef = useRef();
    const handleButtonClick = () => {
        toast.success("URL이 복사되었습니다.");
        navigator.clipboard.writeText(inputRef.current.value);
    };
    const ClkPage = () => {
        navigate(routes.Home);
    };
    return (
        <>
            <div className="ConfirmWrap">
                <div className="ConfirmBoxWrapper FlexCenter">
                    <div className="ConfirmBoxWrapperTitle FlexCenter">
                        <h1>상담신청이 완료되었습니다</h1>
                        <p>순차적으로 연락드리겠습니다.</p>
                    </div>
                    <div className="ConfirmBoxWrapperBox">
                        <div className="ConfirmBoxWrapperBoxTitle">
                            <h1>상담신청 이후 진행과정 [4단계]</h1>
                        </div>
                        <div className="ConfirmBoxWrapperBoxContent">
                            <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                <div className="numberCircle FlexCenter">1</div>
                                <div className="ConfirmBoxWrapperBoxContentItemText">
                                    상세견적 상담
                                </div>
                            </div>
                            <div className="ConfirmBoxWrapperBoxContentIArrow"></div>
                            <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                <div className="numberCircle FlexCenter">2</div>
                                <div className="ConfirmBoxWrapperBoxContentItemText">
                                    견적서 확인 및 결제
                                </div>
                            </div>
                            <div className="ConfirmBoxWrapperBoxContentIArrow"></div>
                            <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                <div className="numberCircle FlexCenter">3</div>
                                <div className="ConfirmBoxWrapperBoxContentItemText">
                                    벽지색상 선택
                                </div>
                            </div>
                            <div className="ConfirmBoxWrapperBoxContentIArrow"></div>
                            <div className="ConfirmBoxWrapperBoxContentItem FlexCenter">
                                <div className="numberCircle FlexCenter">4</div>
                                <div className="ConfirmBoxWrapperBoxContentItemText">
                                    도배시공 🥳
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ConfirmBoxLink FlexCenter">
                        <h1>3초견적 링크 복사/공유하기</h1>
                        {/* <div className="ConfirmBoxLinkBtnWrap FlexCenter">
                            <div className="ConfirmBoxLinkBtn FlexCenter">
                                <img src={ImgData.CopyLink} alt="Link" />
                                <p>링크복사</p>
                            </div>
                            <div className="ConfirmBoxLinkBtn FlexCenter">
                                <img src={ImgData.ChatLink} alt="Link" />
                                <p>문자</p>
                            </div>
                            <div className="ConfirmBoxLinkBtn FlexCenter">
                                <img src={ImgData.KaKao} alt="Link" />
                                <p>카카오톡</p>
                            </div>
                        </div> */}
                        <div className="ConfirmBoxLinkInputBox">
                            <input
                                type="text"
                                value="http://www.8amdobae.co.kr"
                                readOnly
                                ref={inputRef}
                            />
                            <button
                                className="FlexCenter"
                                onClick={handleButtonClick}
                            >
                                URL 복사
                            </button>
                        </div>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        className="ConfirmBoxLinkLastBtn FlexCenter"
                        onClick={ClkPage}
                    >
                        3초견적 바로가기
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Confirm;
