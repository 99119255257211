import ImgData from "../libs/images";
import { Helmet } from "react-helmet-async";
const HelmetWrap = () => {
    return (
        <Helmet>
            <link rel="icon" type="image/svg" href={ImgData.brushBlack} />
            <title>8AMDOBAE</title>
        </Helmet>
    );
};

export default HelmetWrap;
